import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import he from 'he';
import ClickableText from '~components/buttons/ClickableText';
import clsx from '~styles/clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  clickableTextContainer: {
    cursor: 'pointer'
  },
  content: {
    '& > *': {
      color: '#BABABE',
      fontFamily: 'Inter !important',
      fontSize: '16px !important',
      lineHeight: '150% !important',
      textAlign: 'center'
    },
    '& > div > *': {
      color: '#BABABE',
      fontFamily: 'Inter !important',
      fontSize: '16px !important',
      lineHeight: '150% !important',
      textAlign: 'center'
    }
  }
});

const DescriptionText = ({ html, maxLines, onExpandClick }) => {
  const classes = useStyles();
  const [isTextClamped, setIsTextClamped] = useState(false);
  const decodedHtml = he.decode(html);

  const handleReflow = rleState => {
    const { clamped } = rleState;
    setIsTextClamped(clamped);
  };

  const onClick = useCallback(() => {
    if (isTextClamped && onExpandClick) {
      onExpandClick();
    }
  }, [isTextClamped, onExpandClick]);

  return (
    <ClickableText
      onClick={onClick}
      className={clsx(isTextClamped && classes.clickableTextContainer)}
    >
      <HTMLEllipsis
        unsafeHTML={decodedHtml}
        maxLine={maxLines}
        onReflow={handleReflow}
        ellipsis="...▼"
        basedOn="words"
        className={classes.content}
      />
    </ClickableText>
  );
};

DescriptionText.propTypes = {
  html: PropTypes.string.isRequired,
  maxLines: PropTypes.number,
  onExpandClick: PropTypes.func.isRequired
};

DescriptionText.defaultProps = {
  maxLines: 7
};

export default DescriptionText;
